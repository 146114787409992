
  #exerciseDiv {
    margin: 10px 320px;
  }
  
  #headerDiv {
    width: 100%;
    max-width: 350px;
    width: auto;
    margin: auto auto;
    background-color: grey;
    align-items: center;
    margin-top: 10px;
  }
  
  #headerDiv i.icon.times::before {
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 2em;
    display: inline-block;
  }
  
  #headerDiv i::before {
    content: "\f00d";
  }

  .answerDiv {
    border-radius: 5px;
  }

  .answerLeft {
    margin-left: 20px;
    background: #8fd2ec;
  }

  .answerRight {
    margin-right: 20px;
    background: #a5f7a5;
  }

@media (max-width: 700px) {
  #exerciseDiv {
    margin: 10px 10px;
  }
}
