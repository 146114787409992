
.buttonItem {
    padding: 4px;
    margin: 3px 10px;
    border-radius: 6px;
    background-color: #fff;
    border-color: #e5e5e5;
    color: #4b4b4b;
    border: 1.5px solid #4b4b4b;
    font-size: 15px;
    text-align: center;
    font: 500 17px/20px sans-serif;
  }
  
  .buttonItem:hover {
    border: 1.5px solid #bebebe;
    color: #bebebe;
  }
  
  .buttonItem:focus {
    outline: 0;
  }
  
  .selectedButtonItem {
    color: #1cb0f6;
    border-color: #1cb0f6;
  }
  .selectedButtonItem:hover {
    color: #60c8f8;
    border-color: #60c8f8;
  }

  .buttonGripContainer {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
  }
  