.grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0% 20%;
  }
  .grid-item {
    padding: 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 1.5px solid gray;
  }

  #storyTitle {
      padding: 30px 150px;
  }