hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 10px;
}

.selectedOptionsGripContainer {
    display: flexbox;
    height: auto;
    padding: 0px;
    min-height: 34px;
}

/* Selectable options */
.selectableButtonItem {
    padding: 4px;
    margin: 3px 7px;
    border-radius: 6px;
    background-color: #fff;
    border-color: #e5e5e5;
    color: #4b4b4b;
    border: 1.5px solid #4b4b4b;
    font-size: 15px;
    text-align: center;
    font: 500 17px/20px sans-serif;
}
  
.selectableButtonItem:hover {
    border: 1.5px solid #bebebe;
    color: #bebebe;
}
  
.selectableButtonItem:focus {
    outline: 0;
}

.selectableOptionsGripContainer {
    display: flexbox;
}

/* Selected options */
.selectedButtonItem {
    padding: 4px;
    margin: 2px 2px;
    border-radius: 6px;
    background-color: #fff;
    border-color: #e5e5e5;
    color: #4b4b4b;
    border: 1.5px solid #4b4b4b;
    font-size: 15px;
    text-align: center;
    font: 500 17px/20px sans-serif;
}
 
.selectedButtonItem:hover {
    border: 1.5px solid #bebebe;
    color: #bebebe;
}
  
.selectedButtonItem:focus {
    outline: 0;
}