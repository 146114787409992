
img {
    width: 30%;
    height: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

input {
    display: block;
    margin-left: auto;
    margin-right: auto;
}