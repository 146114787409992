.answerInput {
    border-radius: 10px;
    border: 2px solid #e5e5e5;
    background-color: #f7f7f7;
    color: #3c3c3c;
    resize: none;
    font: 400 13.3333px Arial;
    margin: auto auto;
    height: 100%;
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
  }
  