

#searchResultsId {
    text-align: left;
    margin: 0px 30%;
}

#searchResultsId h1 {
    margin-top: 30px;
    margin-bottom: -10px;
}

#searchResultsId h1 span {
    font: 16px Arial,sans-serif;
}

#searchInputDiv {
    padding: 0px 30%;
}

#dictionaryDiv {
    font: 16px Arial,sans-serif;
}

#definitionDiv li {
    margin: 10px auto;
}

#definitionDiv .sentence {
    color: #1a1a1a;
    font-size: 16px;
}

#definitionDiv .trasnlation {
    color: #4a4a4a;
    display: block;
    font-size: 14px;
    font-style: italic;
    margin: 5px;
}

.suggestionWords {
    margin: 10px auto;
}


@media (max-width: 700px) { 
    #searchInputDiv {
        padding: 0px 30px;
    }
    #searchResultsId {
        margin: 0px 15px;
    }
    #searchResultsId h1 {
        font: 26px Arial,sans-serif;
    }
}
  