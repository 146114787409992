

.divInsideMiddle {
    margin: auto auto;
    max-height: 450px;
    height: 100%;
    width: 100%;
    text-align: left;
}
 
.divInsideMiddle h1 {
    color: #3c3c3c;
    font-family:sans-serif;
    font-weight: bold;
    font-size: 26px;
    line-height: 40px;
}
   
.divInsideMiddle p {
    font-size: 20px;
}